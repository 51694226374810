@use 'sass:math';
@import '../../../photon/theme/global';

.section {
  display: grid;
  padding-top: $spacing-l;
  padding-bottom: $spacing-l;
  gap: $spacing-m;

  &:not(.section-masonry) {
    display: grid;
  }

  @media (min-width: $mq-small-and-above) {
    &-cols-3:not(.section-masonry) {
      grid-template-columns: repeat(3, 1fr);

      .text-intro {
        grid-column: span 3;

        @media (min-width: $mq-medium-and-above) {
          width: math.div(2, 3) * 100%;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }

    &-cols-2.section:not(.section-masonry) {
      grid-template-columns: repeat(2, 1fr);
      padding-right: calc(
        max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem)) + min(100%, #{$breakpoint-large}px) / 12
      );
      padding-left: calc(
        max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem)) + min(100%, #{$breakpoint-large}px) / 12
      );

      .text-intro {
        grid-column: span 2;
      }
    }

    @media (min-width: $mq-small-and-above) {
      &.section-masonry {
        display: block;

        > * {
          margin-bottom: $spacing-m;
        }
      }

      &-cols-3.section-masonry {
        column-count: 3;
      }

      &-cols-2.section-masonry {
        column-count: 2;
      }
    }
  }

  @media (min-width: $mq-medium-and-above) {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  @include content-container;
  @include padding-classes;

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      background-color: $color-value;

      @include get-text-color($color-value);
    }
  }
}
